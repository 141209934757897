var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataFound from '../NoDataFound/NoDataFound';
import { Typography } from '@mui/material';
var ContractorsBilling = function (props) {
    var ContractorsOnBillingMetrics = props.ContractorsOnBillingMetrics, onDrillDownData = props.onDrillDownData, setOpenDrillDown = props.setOpenDrillDown, isLoading = props.isLoading;
    var data = ContractorsOnBillingMetrics
        ? ContractorsOnBillingMetrics.data
        : [];
    var handleClick = function (e) {
        var getDrillDownData = data[e.point.index].drillDownData;
        setOpenDrillDown(true);
        onDrillDownData(getDrillDownData);
    };
    var chartOptions = {
        accessibility: {
            enabled: false,
        },
        chart: {
            type: 'area',
            scrollablePlotArea: {
                minWidth: 600,
            },
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        xAxis: {
            categories: data === null || data === void 0 ? void 0 : data.map(function (item) { return item.label; }),
            tickmarkPlacement: 'on',
            title: {
                text: '',
            },
        },
        yAxis: {
            title: {
                text: 'Contractors on Billing',
            },
        },
        tooltip: {
            enabled: false,
            shared: true,
        },
        legend: {
            enabled: false, // Disable legend
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: handleClick,
                    },
                },
            },
            area: {
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.y ? this.y : null;
                    },
                    style: {
                        color: '#000',
                        textOutline: 'none',
                        cursor: 'pointer',
                    },
                    y: -1,
                },
            },
        },
        series: [
            {
                type: 'area',
                name: 'KPI Count',
                data: data === null || data === void 0 ? void 0 : data.map(function (item) { return item.value; }),
                lineWidth: 3,
                color: '#2A70EA',
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, 'rgba(190, 227, 248, 0.75)'],
                        [1, 'rgba(190, 227, 248, 0)'],
                    ],
                },
            },
        ],
    };
    return (_jsx(_Fragment, { children: ContractorsOnBillingMetrics &&
            ContractorsOnBillingMetrics.data.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(HighchartsReact, { highcharts: Highcharts, options: chartOptions }), _jsx(Typography, __assign({ style: { padding: '15px' } }, { children: "Click the values on the curve to drill down." }))] })) : (_jsx(NoDataFound, { isLoading: isLoading })) }));
};
export default ContractorsBilling;
