var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, Grid, Typography, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Stack, } from '@mui/material';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CustomPagination from './CustomPagination';
import StatusCell from './StatusCell';
import { StyledTableCell, StyledTableRow, EmailCell, placementsStyles, CustomScrollContainer, } from './Placements.styled';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import FilterDrawer from './FilterDrawer';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import NoPageFound from '../../../../tandym-web-common/src/components/NoPageFound';
import { useClientAppSelector } from '../../store/hook';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { isMobile } from '../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
var Placements = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState([]), placementData = _b[0], setPlacementData = _b[1]; // original data
    var _c = useState([]), filteredPlacementData = _c[0], setFilteredPlacementData = _c[1];
    var _d = useState([]), allJobTitles = _d[0], setAllJobTitles = _d[1];
    var _e = useState([]), allHiringManagers = _e[0], setAllHiringManagers = _e[1];
    var _f = useState([]), allCandidates = _f[0], setAllCandidates = _f[1];
    var _g = useState([]), allTimesheetApprovers = _g[0], setAllTimesheetApprovers = _g[1];
    var _h = useState([]), allSkillSets = _h[0], setAllSkillSets = _h[1];
    var httpGet = useAPI().httpGet;
    // const employmentTypes = ['Contract', 'Temporary', 'Permanent'];
    var employmentTypes = ['Contract', 'Permanent'];
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _j = useState(true), renderComponent = _j[0], setRenderComponent = _j[1];
    useEffect(function () {
        var _a;
        setRenderComponent((_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.placement);
    }, [entitlementsData]);
    useEffect(function () {
        if (placementData && (placementData === null || placementData === void 0 ? void 0 : placementData.length) > 0) {
            var uniqueJobTitles = Array.from(new Set(placementData.map(function (item) { return item.job_Title; })));
            var uniqueHiringManagers = Array.from(new Set(placementData.map(function (item) { return item.hiring_Manager; })));
            var uniqueCandidates = Array.from(new Set(placementData.map(function (item) { return item.candidate; })));
            var uniqueTimesheetApprovers = Array.from(new Set(placementData.map(function (item) { return item.timesheet_Approver; })));
            var uniqueSkillSets = Array.from(new Set(placementData.map(function (item) { return item.functional_Skill_Set; })));
            setAllJobTitles(uniqueJobTitles);
            setAllHiringManagers(uniqueHiringManagers);
            setAllCandidates(uniqueCandidates);
            setAllTimesheetApprovers(uniqueTimesheetApprovers);
            setAllSkillSets(uniqueSkillSets);
        }
    }, [placementData]);
    var _k = useState(0), page = _k[0], setPage = _k[1];
    var _l = useState(10), rowsPerPage = _l[0], setRowsPerPage = _l[1];
    var _m = useState(false), filterDrawerOpen = _m[0], setFilterDrawerOpen = _m[1];
    var _o = useState({}), sortOrder = _o[0], setSortOrder = _o[1];
    var _p = useState('ALL'), selectedTab = _p[0], setSelectedTab = _p[1];
    var _q = useState([]), selectedEmploymentType = _q[0], setSelectedEmploymentType = _q[1];
    var _r = useState(''), selectedJobTitle = _r[0], setSelectedJobTitle = _r[1];
    var _s = useState(''), selectedHiringManager = _s[0], setSelectedHiringManager = _s[1];
    var _t = useState(''), selectedCandidate = _t[0], setSelectedCandidate = _t[1];
    var _u = useState(''), selectedTimesheetApprover = _u[0], setSelectedTimesheetApprover = _u[1];
    var _v = useState(''), selectedSkillSet = _v[0], setSelectedSkillSet = _v[1];
    var _w = useState(null), startDateFrom = _w[0], setStartDateFrom = _w[1];
    var _x = useState(null), startDateTo = _x[0], setStartDateTo = _x[1];
    var _y = useState(null), endDateFrom = _y[0], setEndDateFrom = _y[1];
    var _z = useState(null), endDateTo = _z[0], setEndDateTo = _z[1];
    var _0 = useState([]), dynamicTabs = _0[0], setDynamicTabs = _0[1];
    var MailOutlineRoundedIconWidthHeight = 24;
    var handleOpenDrawer = function () {
        setFilterDrawerOpen(true);
        ReactGA.event({
            category: 'Client',
            action: 'Click',
            label: "Placements Filter Icon",
        });
    };
    var handleCloseDrawer = function () { return setFilterDrawerOpen(false); };
    var handleFilterApply = function () {
        var filteredData = placementData;
        if (selectedEmploymentType.length > 0) {
            filteredData = filteredData.filter(function (item) {
                return selectedEmploymentType.includes(item.type);
            });
        }
        if (selectedJobTitle) {
            filteredData = filteredData.filter(function (item) {
                return item.job_Title === selectedJobTitle;
            });
        }
        if (selectedHiringManager) {
            filteredData = filteredData.filter(function (item) { return item.hiring_Manager === selectedHiringManager; });
        }
        if (selectedCandidate) {
            filteredData = filteredData.filter(function (item) { return item.candidate === selectedCandidate; });
        }
        if (selectedTimesheetApprover) {
            filteredData = filteredData.filter(function (item) { return item.timesheet_Approver === selectedTimesheetApprover; });
        }
        if (selectedSkillSet) {
            filteredData = filteredData.filter(function (item) {
                return item.functional_Skill_Set.includes(selectedSkillSet);
            });
        }
        if (startDateFrom || startDateTo) {
            filteredData = filteredData.filter(function (item) {
                var startDate = new Date(item.start);
                if (isNaN(startDate.getTime())) {
                    return false;
                }
                if (startDateFrom && startDate < startDateFrom)
                    return false;
                if (startDateTo && startDate > startDateTo)
                    return false;
                return true;
            });
        }
        if (endDateFrom || endDateTo) {
            filteredData = filteredData.filter(function (item) {
                var endDate = new Date(item.schedule_End);
                if (isNaN(endDate.getTime())) {
                    return false;
                }
                if (endDateFrom && endDate < endDateFrom)
                    return false;
                if (endDateTo && endDate > endDateTo)
                    return false;
                return true;
            });
        }
        setFilteredPlacementData(filteredData);
        setFilterDrawerOpen(false);
        setPage(0);
    };
    var handleEmploymentTypeChange = function (value) {
        setSelectedEmploymentType(value);
    };
    var handleJobTitleChange = function (event) {
        setSelectedJobTitle(event.target.value);
    };
    var handleHiringManagerChange = function (event) {
        setSelectedHiringManager(event.target.value);
    };
    var handleCandidateChange = function (event) {
        setSelectedCandidate(event.target.value);
    };
    var handleTimesheetApproverChange = function (event) {
        setSelectedTimesheetApprover(event.target.value);
    };
    var handleSkillSetChange = function (event) {
        setSelectedSkillSet(event.target.value);
    };
    var columnKeyMap = {
        Candidate: 'candidate',
        'Job Title': 'job_Title',
        'Functional Skill Set': 'functional_Skill_Set',
        Status: 'status',
        Type: 'type',
        'Hiring Manager': 'hiring_Manager',
        'Reference ID': 'reference_No',
        'Primary Timesheet Approver': 'timesheet_Approver',
        Start: 'start',
        'Schedule End': 'schedule_End',
    };
    var handleSort = function (columnDisplayName) {
        var _a;
        var columnKey = columnKeyMap[columnDisplayName];
        var isAsc = sortOrder[columnKey] === 'asc';
        var order = isAsc ? 'desc' : 'asc';
        setSortOrder(function (prevSortOrder) {
            var _a;
            return (__assign(__assign({}, prevSortOrder), (_a = {}, _a[columnKey] = order, _a)));
        });
        if (!filteredPlacementData)
            return;
        var sortedData = (_a = __spreadArray([], filteredPlacementData, true)) === null || _a === void 0 ? void 0 : _a.sort(function (a, b) {
            var aValue = a[columnKey];
            var bValue = b[columnKey];
            if (aValue === null || bValue === null)
                return 0;
            if (columnKey === 'start' || columnKey === 'schedule_End') {
                var dateA = new Date(aValue);
                var dateB = new Date(bValue);
                return order === 'asc'
                    ? dateA.getTime() - dateB.getTime()
                    : dateB.getTime() - dateA.getTime();
            }
            if (aValue < bValue)
                return order === 'asc' ? -1 : 1;
            if (aValue > bValue)
                return order === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredPlacementData(sortedData);
    };
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
    };
    var handleTabChange = function (event, newValue) {
        setSelectedTab(newValue);
        setPage(0); // Reset to page 1 when tab changes
    };
    var filteredData = filteredPlacementData && (filteredPlacementData === null || filteredPlacementData === void 0 ? void 0 : filteredPlacementData.length) > 0
        ? filteredPlacementData
            .map(function (item) { return (__assign(__assign({}, item), { statusReasonGroup: item.statusReasonGroup
                ? item.statusReasonGroup
                : 'Pending' })); })
            .filter(function (item) {
            return selectedTab === 'ALL' ||
                item.statusReasonGroup.toUpperCase() === selectedTab;
        })
        : [];
    var fetchClientPlacementsInfo = useCallback(function (cancelToken) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CLIENT_PLACEMENTS_GET, undefined, true, cancelToken)];
                case 1:
                    response = _b.sent();
                    if (!(response === null || response === void 0 ? void 0 : response.error)) {
                        setPlacementData(response === null || response === void 0 ? void 0 : response.response);
                        setFilteredPlacementData(response === null || response === void 0 ? void 0 : response.response);
                        setDynamicTabs(Array.from(new Set(['ALL'].concat((_a = response === null || response === void 0 ? void 0 : response.response) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                            return (item.statusReasonGroup
                                ? item.statusReasonGroup
                                : 'Pending').toUpperCase();
                        })))));
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _b.sent();
                    if (axios.isCancel(error_1)) {
                        console.error('Fetch canceled:', error_1.message); // Handle cancellation case
                    }
                    else {
                        console.error('Fetch client placements info error:', error_1);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    if (!cancelToken.aborted)
                        setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, []);
    useEffect(function () {
        var controller = new AbortController(); // Create a stable AbortController instance
        fetchClientPlacementsInfo(controller.signal);
        return function () {
            controller.abort(); // Abort when the component unmounts
        };
    }, [fetchClientPlacementsInfo]);
    if (!renderComponent) {
        return _jsx(NoPageFound, {});
    }
    var mobilePlacementHeader = function () {
        return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ item: true, mt: 1, sx: {
                        display: 'flex',
                        direction: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pr: 2,
                    } }, { children: [_jsx(Typography, __assign({ variant: 'h5', sx: placementsStyles.tableHead }, { children: "Placements" })), _jsx(FilterAltOutlinedIcon, { id: 'filter-icon', "data-testid": 'filter-icon', component: 'svg', color: 'primary', sx: { cursor: 'pointer' }, onClick: handleOpenDrawer })] })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Box, __assign({ sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        } }, { children: _jsx(Tabs, __assign({ value: selectedTab, onChange: handleTabChange, id: 'placements-tabs', "data-testid": 'placements-tabs' }, { children: dynamicTabs === null || dynamicTabs === void 0 ? void 0 : dynamicTabs.map(function (tab) {
                                return (_jsx(Tab, { label: tab, value: tab, id: "tab-".concat(tab), "data-testid": "tab-".concat(tab), style: {
                                        fontSize: fontSizeDynamic(12),
                                        fontWeight: 300,
                                    } }, tab));
                            }) })) })) }))] }));
    };
    var largeScreenPlacementHeader = function () {
        return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ item: true, mt: 1, sx: {
                        display: 'flex',
                        justifyContent: 'space-between',
                    } }, { children: [_jsx(Typography, __assign({ variant: 'h5', sx: placementsStyles.tableHead }, { children: "Placements" })), _jsx(Typography, __assign({ sx: placementsStyles.tableNote }, { children: "Use the left/right arrow keys, mouse, or touchpad to scroll through the table content." }))] })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        } }, { children: [_jsx(Tabs, __assign({ value: selectedTab, onChange: handleTabChange, id: 'placements-tabs', "data-testid": 'placements-tabs' }, { children: dynamicTabs === null || dynamicTabs === void 0 ? void 0 : dynamicTabs.map(function (tab) {
                                    return (_jsx(Tab, { label: tab, value: tab, id: "tab-".concat(tab), "data-testid": "tab-".concat(tab), style: {
                                            fontSize: fontSizeDynamic(16),
                                            fontWeight: 300,
                                        } }, tab));
                                }) })), _jsx(Box, __assign({ sx: placementsStyles.tabBox }, { children: _jsx(FilterAltOutlinedIcon, { id: 'filter-icon', "data-testid": 'filter-icon', component: 'svg', color: 'primary', sx: { cursor: 'pointer' }, onClick: handleOpenDrawer }) }))] })) }))] }));
    };
    return (_jsx(Grid, __assign({ container: true, id: 'placements', "data-testid": 'placements', p: 2 }, { children: _jsxs(Box, __assign({ sx: { p: 2, background: '#fff', width: '95%' } }, { children: [!isMobile() ? largeScreenPlacementHeader() : mobilePlacementHeader(), _jsxs(Grid, __assign({ item: true }, { children: [_jsx(TableContainer, __assign({ component: Paper }, { children: _jsx(CustomScrollContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: placementsStyles.stickyColumCandidate }, { children: _jsxs(Stack, __assign({ id: 'candidate-header', "data-testid": 'candidate-header', direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort('Candidate'); } }, { children: [_jsx("span", __assign({ style: {
                                                                        fontSize: fontSizeDynamic(14),
                                                                        fontWeight: 300,
                                                                    } }, { children: "CANDIDATE" })), _jsx(TPSvgIcon, { resourceName: 'sort-icon' })] })) })), [
                                                        'Job Title',
                                                        'Functional Skill Set',
                                                        'Status',
                                                        'Type',
                                                        'Hiring Manager',
                                                        'Reference ID',
                                                        'Primary Timesheet Approver',
                                                        'Start',
                                                        'Schedule End',
                                                    ].map(function (columnDisplayName) { return (_jsx(StyledTableCell, { children: _jsxs(Stack, __assign({ id: "table-header-".concat(columnDisplayName), "data-testid": "table-header-".concat(columnDisplayName), direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort(columnDisplayName); } }, { children: [_jsx("span", __assign({ style: {
                                                                        fontSize: fontSizeDynamic(14),
                                                                        fontWeight: 300,
                                                                    } }, { children: columnDisplayName.toUpperCase() })), _jsx(TPSvgIcon, { resourceName: 'sort-icon', id: "sort-icon-".concat(columnDisplayName), "data-testid": "sort-icon-".concat(columnDisplayName) })] })) }, columnDisplayName)); }), _jsx(EmailCell, { children: _jsx(Stack, __assign({ direction: 'row', spacing: 1, alignItems: 'center' }, { children: _jsx("span", __assign({ style: {
                                                                    fontSize: fontSizeDynamic(14),
                                                                    fontWeight: 300,
                                                                } }, { children: "TANDYM CONTACT" })) })) })] }) }), _jsx(TableBody, { children: (filteredData === null || filteredData === void 0 ? void 0 : filteredData.length) > 0 ? (filteredData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map(function (row, index) {
                                                var _a;
                                                var clientEmail = row.clientRelationshipOwnerEmail || '';
                                                var consultantEmail = row.consultantEmail || '';
                                                var emails = [clientEmail, consultantEmail]
                                                    .filter(function (email) { return email; })
                                                    .join(',');
                                                var mailtoHref = emails
                                                    ? "mailto:".concat(emails, "?subject=").concat(encodeURIComponent(row.subject))
                                                    : null;
                                                return (_jsxs(StyledTableRow, { children: [_jsx(TableCell, __assign({ sx: placementsStyles.stickyColumCandidate }, { children: _jsxs(Box, __assign({ sx: {
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                } }, { children: [_jsx(Avatar, __assign({ sx: placementsStyles.avatar, alt: row.candidate, id: "avatar-candidate-".concat(row.candidate), "data-testid": "avatar-candidate-".concat(row.candidate) }, { children: row.candidate
                                                                            ? row.candidate.charAt(0).toUpperCase()
                                                                            : null })), _jsx("span", __assign({ style: placementsStyles.candidateName }, { children: row.candidate }))] })) })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom, id: "table-data-job-title-".concat(row.job_Title), "data-testid": "table-data-job-title-".concat(row.job_Title) }, { children: row.job_Title })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom, id: "table-data-".concat(row.functional_Skill_Set), "data-testid": "table-data-".concat(row.functional_Skill_Set) }, { children: row.functional_Skill_Set })), _jsx(StatusCell, { status: row.status, statusReasonGroup: row.statusReasonGroup, id: row.status }), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom, id: "table-data-".concat(row.type), "data-testid": "table-data-".concat(row.type) }, { children: row.type === 'Temporary' ? 'Contract' : row.type })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: _jsxs(Box, __assign({ sx: {
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }, id: "table-data-".concat(row.hiring_Manager), "data-testid": "table-data-".concat(row.hiring_Manager) }, { children: [_jsx(Avatar, __assign({ sx: placementsStyles.avatar, alt: row.candidate, id: "avatar-".concat(row.hiring_Manager), "data-testid": "avatar-".concat(row.hiring_Manager) }, { children: row.hiring_Manager
                                                                            ? row.hiring_Manager.charAt(0).toUpperCase()
                                                                            : null })), _jsx(Typography, __assign({ sx: placementsStyles.candidateName }, { children: row.hiring_Manager }))] })) })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom, id: "table-data-".concat(row.reference_No), "data-testid": "table-data-".concat(row.reference_No) }, { children: row.reference_No })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: _jsxs(Box, __assign({ sx: {
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }, id: "table-data-".concat(row.timesheet_Approver), "data-testid": "table-data-".concat(row.timesheet_Approver) }, { children: [row.timesheet_Approver ? (_jsx(Avatar, __assign({ sx: placementsStyles.avatar, alt: row.candidate, id: "avatar-".concat(row.timesheet_Approver), "data-testid": "avatar-".concat(row.timesheet_Approver) }, { children: row.timesheet_Approver
                                                                            ? row === null || row === void 0 ? void 0 : row.timesheet_Approver.charAt(0).toUpperCase()
                                                                            : null }))) : null, _jsx(Typography, __assign({ sx: placementsStyles.candidateName }, { children: (_a = row.timesheet_Approver) !== null && _a !== void 0 ? _a : 'NA' }))] })) })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom, id: "table-data-".concat(row.start), "data-testid": "table-data-".concat(row.start) }, { children: row.start ? row.start : 'NA' })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom, id: "table-data-".concat(row.schedule_End), "data-testid": "table-data-".concat(row.schedule_End) }, { children: row.schedule_End ? row.schedule_End : 'NA' })), _jsx(TableCell, __assign({ sx: placementsStyles.stickyColumEmail, id: "table-data-".concat(index), "data-testid": "table-data-".concat(index) }, { children: _jsx("a", __assign({ href: mailtoHref ? mailtoHref : undefined, className: 'emailIcon' }, { children: _jsx(MailOutlineRoundedIcon, { sx: {
                                                                        cursor: 'pointer',
                                                                        width: MailOutlineRoundedIconWidthHeight,
                                                                        height: MailOutlineRoundedIconWidthHeight,
                                                                    } }) })) }))] }, 'table-data' + index));
                                            })) : (_jsx(StyledTableRow, { children: _jsx(TableCell, __assign({ colSpan: 11, align: 'center', style: { fontSize: fontSizeDynamic(14) } }, { children: "No data found" })) })) })] }) }) })), _jsx(CustomPagination, { count: filteredData.length, page: page, rowsPerPage: rowsPerPage, onPageChange: handleChangePage })] })), _jsx(FilterDrawer, { open: filterDrawerOpen, onClose: handleCloseDrawer, employmentTypes: employmentTypes, selectedEmploymentType: selectedEmploymentType, onEmploymentTypeChange: handleEmploymentTypeChange, allJobTitles: allJobTitles, selectedJobTitle: selectedJobTitle, onJobTitleChange: handleJobTitleChange, allHiringManagers: allHiringManagers, selectedHiringManager: selectedHiringManager, onHiringManagerChange: handleHiringManagerChange, allCandidates: allCandidates, selectedCandidate: selectedCandidate, onCandidateChange: handleCandidateChange, allTimesheetApprovers: allTimesheetApprovers, selectedTimesheetApprover: selectedTimesheetApprover, onTimesheetApproverChange: handleTimesheetApproverChange, allSkillSets: allSkillSets, selectedSkillSet: selectedSkillSet, onSkillSetChange: handleSkillSetChange, startDateFrom: startDateFrom, setStartDateFrom: setStartDateFrom, startDateTo: startDateTo, setStartDateTo: setStartDateTo, endDateFrom: endDateFrom, setEndDateFrom: setEndDateFrom, endDateTo: endDateTo, setEndDateTo: setEndDateTo, onApply: handleFilterApply }), isLoading && _jsx(TPProgressModal, { isLoading: isLoading })] })) })));
};
export default Placements;
