var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Container, List, ListItem, Typography } from '@mui/material';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { FaqsStyle } from './Faqs.styled';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import minus_icon_normal from '../../../../tandym-web-common/src/images/minus_icon-normal.png';
import { useNavigate } from 'react-router-dom';
import { useClientAppSelector } from '../../store/hook';
var Faqs = function () {
    var navigate = useNavigate();
    var _a = React.useState([]), isOpen = _a[0], setIsOpen = _a[1];
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var widthForImages = window.innerWidth / 95;
    var faqsId = 'faqs';
    var handleOpen = function (id) {
        if (isOpen.includes(id)) {
            setIsOpen(isOpen.filter(function (item) { return item !== id; }));
        }
        else {
            setIsOpen(__spreadArray([id], isOpen, true));
        }
    };
    var handleCollapseAll = function () {
        if (isOpen.length > 0) {
            setIsOpen([]);
        }
        else {
            setIsOpen(['1', '2', '3', '4', '5', '6']);
        }
    };
    return (_jsx(Container, __assign({ maxWidth: 'xl', sx: { mt: 3, mb: 5 }, id: 'faqs', "data-testid": 'faqs' }, { children: _jsx(Box, __assign({ sx: {
                p: 2,
                background: colorCodes.backgroundColors.white,
                minHeight: '550px',
            } }, { children: _jsxs(Box, __assign({ pr: 2 }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex' } }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ sx: FaqsStyle.headerText, id: "".concat(faqsId, "-title"), "data-testid": "".concat(faqsId, "-title") }, { children: "Frequently Asked Questions" }))] })), _jsxs(Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        } }, { children: [_jsx(Typography, __assign({ sx: FaqsStyle.subHeading, id: "".concat(faqsId, "-description") }, { children: "These FAQs can help ensure that our clients have a clear understanding of how to navigate the portal and make the most of the available features and data." })), _jsx(Typography, __assign({ id: "".concat(faqsId, "-CollapseAllAndExpandAll"), "data-testid": "".concat(faqsId, "-CollapseAllAndExpandAll"), sx: FaqsStyle.expandAllText, onClick: handleCollapseAll }, { children: isOpen.length < 1 ? 'Expand' : 'Collapse' }))] })), entitlementsData.entitlementDto.timesheets && (_jsxs(Box, __assign({ sx: FaqsStyle.borderStyle }, { children: [_jsxs(Box, __assign({ sx: FaqsStyle.faqsBoxContainer }, { children: [_jsx(Typography, __assign({ id: 'faqs-1', "data-testid": 'faqs-1', sx: FaqsStyle.faqsHeader, onClick: function () { return handleOpen('1'); } }, { children: "How can I approve time for my contingent worker?" })), _jsx(Box, __assign({ id: "".concat(faqsId, "-pluseAndMinus-icons-1"), "data-testid": "".concat(faqsId, "-pluseAndMinus-icons-1"), sx: FaqsStyle.pointer, onClick: function () { return handleOpen('1'); } }, { children: isOpen.includes('1') ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx(TPSvgIcon, { resourceName: 'faqs-plus-icon', height: 24 })) }))] })), isOpen.includes('1') && (_jsx(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                } }, { children: _jsx(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: "Please approve all time entered in the Tandym Portal by 10am on Mondays to ensure on time payments are made to our contingent workers & consultants." })) })))] }))), entitlementsData.entitlementDto.placement && (_jsxs(Box, __assign({ sx: FaqsStyle.borderStyle }, { children: [_jsxs(Box, __assign({ sx: FaqsStyle.faqsBoxContainer }, { children: [_jsx(Typography, __assign({ id: 'faqs-2', "data-testid": 'faqs-2', sx: FaqsStyle.faqsHeader, onClick: function () { return handleOpen('2'); } }, { children: "How can I view the current status of placements for my contingent workers?" })), _jsx(Box, __assign({ id: "".concat(faqsId, "-pluseAndMinus-icons-2"), "data-testid": "".concat(faqsId, "-pluseAndMinus-icons-2"), sx: FaqsStyle.pointer, onClick: function () { return handleOpen('2'); } }, { children: isOpen.includes('2') ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx(TPSvgIcon, { resourceName: 'faqs-plus-icon', height: 24 })) }))] })), isOpen.includes('2') && (_jsx(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                } }, { children: _jsxs(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: ["To view the status of contractors and consultants currently engaged with your organization; including end dates, rates and approving managers", ' ', _jsx("span", __assign({ id: "".concat(faqsId, "-navigate-placements-click"), "data-testid": "".concat(faqsId, "-navigate-placements-click"), onClick: function () {
                                                navigate('/placements');
                                            }, style: FaqsStyle.listItemInnerTextBlue }, { children: "Click Here" })), "."] })) })))] }))), _jsxs(Box, __assign({ sx: FaqsStyle.borderStyle }, { children: [_jsxs(Box, __assign({ sx: FaqsStyle.faqsBoxContainer }, { children: [_jsx(Typography, __assign({ id: 'faqs-3', "data-testid": 'faqs-3', sx: FaqsStyle.faqsHeader, onClick: function () { return handleOpen('3'); } }, { children: "How do I view my job orders or requests?" })), _jsx(Box, __assign({ id: "".concat(faqsId, "-pluseAndMinus-icons-3"), "data-testid": "".concat(faqsId, "-pluseAndMinus-icons-3"), sx: FaqsStyle.pointer, onClick: function () { return handleOpen('3'); } }, { children: isOpen.includes('3') ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx(TPSvgIcon, { resourceName: 'faqs-plus-icon', height: 24 })) }))] })), isOpen.includes('3') && (_jsxs(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                } }, { children: [_jsxs(ListItem, __assign({ alignItems: 'flex-start', sx: FaqsStyle.listItem }, { children: ["You can view your current open vacancies and their status here", ' ', _jsx("span", __assign({ id: "".concat(faqsId, "-navigate-home-click"), "data-testid": "".concat(faqsId, "-navigate-home-click"), onClick: function () {
                                                    navigate('/home');
                                                }, style: FaqsStyle.listItemInnerTextBlue }, { children: "Click Here" })), "."] })), _jsxs(ListItem, __assign({ alignItems: 'flex-start', sx: FaqsStyle.listItem }, { children: ["To discuss an open vacancy or upcoming project where your organization requires assistance, or to inquire about additional solution offerings", ' ', _jsx("span", __assign({ id: "".concat(faqsId, "clickHere-3"), "data-testid": "".concat(faqsId, "clickHere-3"), onClick: function () {
                                                    navigate('/home');
                                                }, style: FaqsStyle.listItemInnerTextBlue }, { children: "Click Here" })), "."] }))] })))] })), _jsxs(Box, __assign({ sx: FaqsStyle.borderStyle }, { children: [_jsxs(Box, __assign({ sx: FaqsStyle.faqsBoxContainer }, { children: [_jsx(Typography, __assign({ id: 'faqs-4', "data-testid": 'faqs-4', sx: FaqsStyle.faqsHeader, onClick: function () { return handleOpen('4'); } }, { children: "I noticed Tandym offers solutions beyond the services currently provided to my organization. How can I learn more about Tandym\u2019s Specialized Total Talent Solutions?" })), _jsx(Box, __assign({ id: "".concat(faqsId, "-pluseAndMinus-icons-4"), "data-testid": "".concat(faqsId, "-pluseAndMinus-icons-4"), sx: FaqsStyle.pointer, onClick: function () { return handleOpen('4'); } }, { children: isOpen.includes('4') ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx(TPSvgIcon, { resourceName: 'faqs-plus-icon', height: 24 })) }))] })), isOpen.includes('4') && (_jsx(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                } }, { children: _jsxs(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: ["Interested in learning more about the solutions that Tandym offers, reach out to your Tandym contact", ' ', _jsx("span", __assign({ id: "".concat(faqsId, "-navigate-contact-click"), "data-testid": "".concat(faqsId, "-navigate-contact-click"), onClick: function () {
                                                navigate('/infoCenter/contact');
                                            }, style: FaqsStyle.listItemInnerTextBlue }, { children: "Click Here" })), "."] })) })))] })), _jsxs(Box, __assign({ sx: FaqsStyle.borderStyle }, { children: [_jsxs(Box, __assign({ sx: FaqsStyle.faqsBoxContainer }, { children: [_jsx(Typography, __assign({ id: 'faqs-5', "data-testid": 'faqs-5', sx: FaqsStyle.faqsHeader, onClick: function () { return handleOpen('5'); } }, { children: "Can I get insights into market trends or insights into Tandym\u2019s service level performance?" })), _jsx(Box, __assign({ id: "".concat(faqsId, "-pluseAndMinus-icons-5"), "data-testid": "".concat(faqsId, "-pluseAndMinus-icons-5"), sx: FaqsStyle.pointer, onClick: function () { return handleOpen('5'); } }, { children: isOpen.includes('5') ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx(TPSvgIcon, { resourceName: 'faqs-plus-icon', height: 24 })) }))] })), isOpen.includes('5') && (_jsxs(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                } }, { children: [_jsxs(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: ["We feel the key to a great partnership is transparency. Our", ' ', _jsx("span", __assign({ id: "".concat(faqsId, "-navigate-OperationalMetrics-click"), "data-testid": "".concat(faqsId, "-navigate-OperationalMetrics-click"), onClick: function () {
                                                    navigate('/home');
                                                }, style: FaqsStyle.listItemInnerTextBlue }, { children: "Operational Metrics" })), ' ', "provide insights into both Tandym\u2019s performance providing Talent Solutions, as well as our clients response time."] })), _jsx(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: "Additionally, we provide a similar performance view which encompasses the entirety of Tandym\u2019s client performance to provide a more comprehensive and comparative picture." }))] })))] })), _jsxs(Box, __assign({ sx: FaqsStyle.borderStyle }, { children: [_jsxs(Box, __assign({ sx: FaqsStyle.faqsBoxContainer }, { children: [_jsx(Typography, __assign({ id: 'faqs-6', "data-testid": 'faqs-6', sx: FaqsStyle.faqsHeader, onClick: function () { return handleOpen('6'); } }, { children: "What is the process for providing feedback on any current or past placements?" })), _jsx(Box, __assign({ id: "".concat(faqsId, "-pluseAndMinus-icons-6"), "data-testid": "".concat(faqsId, "-pluseAndMinus-icons-6"), sx: FaqsStyle.pointer, onClick: function () { return handleOpen('6'); } }, { children: isOpen.includes('6') ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx(TPSvgIcon, { resourceName: 'faqs-plus-icon', height: 24 })) }))] })), isOpen.includes('6') && (_jsxs(List, __assign({ sx: {
                                    listStyleType: 'disc',
                                    listStylePosition: 'inside',
                                } }, { children: [_jsx(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: "As part of our version 2 upgrade, a formal rating on any contingent workers will be sent to you upon the end of the assignment." })), _jsx(ListItem, __assign({ sx: FaqsStyle.listItem }, { children: "Ongoing feedback should be provided to your sales representative as part of their regular touch base with you at the 30-60-90 day mark or during the quarterly business review." }))] })))] }))] })) })) })));
};
export default Faqs;
