var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { InfoCenterStyles } from './InfoCenterStyles';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { Link } from 'react-router-dom';
import { useClientAppSelector } from '../../store/hook';
import contactImg from '../../images/Contact.png';
var Contact = function () {
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var backgroundImgHeight = (window.innerWidth * 10) / 52;
    return (_jsxs(Box, __assign({ id: 'contact', sx: InfoCenterStyles.contact }, { children: [_jsx(Box, __assign({ sx: [
                    InfoCenterStyles.contactImg,
                    {
                        backgroundImage: "url(".concat(contactImg, ")"),
                        width: '100%',
                        height: backgroundImgHeight,
                    },
                ] }, { children: _jsxs(Box, __assign({ sx: InfoCenterStyles.contactImgContent }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(32), sx: InfoCenterStyles.contactText }, { children: "Contact" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(18), sx: InfoCenterStyles.contactInfo }, { children: "Have questions? We're here to help!" }))] })) })), _jsx(Box, __assign({ sx: { flexGrow: 1 } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2, sx: {
                        mt: '8px',
                        width: '100%',
                        ml: 0,
                    } }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, sx: __assign(__assign({}, InfoCenterStyles.generalContactSection), { pl: '0px !important' }) }, { children: _jsxs(Box, __assign({ sx: InfoCenterStyles.cardSection }, { children: [_jsx(Typography, __assign({ sx: __assign(__assign({}, InfoCenterStyles.cardText), { mb: 4, fontSize: fontSizeDynamic(20), fontWeight: 600 }) }, { children: "General Contact" })), _jsx(Typography, __assign({ sx: __assign(__assign({}, InfoCenterStyles.cardText), { mt: 2, fontSize: fontSizeDynamic(18) }) }, { children: "For portal support, or questions regarding Time sheet and expenses, please contact" })), _jsx(Typography, __assign({ sx: __assign(__assign({}, InfoCenterStyles.cardText), { mt: '17px', fontSize: fontSizeDynamic(20) }) }, { children: _jsx("a", __assign({ href: 'mailto:clientsupport@tandymgroup.com' }, { children: "clientsupport@tandymgroup.com" })) }))] })) })), entitlementsData.entitlementDto.placement && (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, sx: InfoCenterStyles.generalContactSection }, { children: _jsxs(Box, __assign({ sx: InfoCenterStyles.cardSection }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(20), sx: __assign(__assign({}, InfoCenterStyles.cardText), { fontWeight: 600 }) }, { children: "Placement Specific Tandym Contact" })), _jsx(Typography, __assign({ sx: __assign(__assign({}, InfoCenterStyles.cardText), { mt: '17px', fontSize: fontSizeDynamic(18) }) }, { children: "For assistance with current Placements, Open Jobs, or additional Tandym Solutions, contact your primary Tandym contact for the Placement or Job." })), _jsx(Link, __assign({ to: '/placements' }, { children: _jsxs(TPButton, __assign({ color: 'primary', sx: { mt: '14px', fontSize: fontSizeDynamic(14) } }, { children: ["Tandym Contact", _jsx(TPSvgIcon, { resourceName: 'arrow-left-alt' })] })) }))] })) })))] })) }))] })));
};
export default Contact;
