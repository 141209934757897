import Client_Infocenter_Who_We_Are_1 from "../../images/Client_Infocenter_Who_We_Are_1.png";
import Client_Infocenter_Specialized_Talent_Solutions_1 from "../../images/Client_Infocenter_Specialized_Talent_Solutions_1.png";
import Recruitment_Expertise_1 from "../../images/Recruitment_Expertise_1.png";
var aboutTandymLink = function (btnUrl) {
    var windowFeatures = "width=500,height=350,left=500,top=200";
    window.open(btnUrl, "_blank", windowFeatures);
};
export var aboutTandymContent = [
    {
        title: "Who We Are",
        image: Client_Infocenter_Who_We_Are_1,
        description: "Since 1985, Tandym Group has evolved from a niche recruitment firm to a comprehensive workforce solutions provider, maintaining our core values of personalized service and expertise while expanding nationwide across industries. Our human-centered approach is enhanced by best-in-class AI, delivering superior insights and connections to drive businesses and careers forward.",
        buttonLabel: "Learn More",
        onClickBtn: function () {
            aboutTandymLink('https://tandymgroup.com/about/');
        },
    },
    {
        title: "Specialized Solutions",
        image: Client_Infocenter_Specialized_Talent_Solutions_1,
        description: "The Tandym difference emphasizes forging strong partnerships with both clients and job seekers. Our commitment is grounded in providing outstanding service, maintaining transparency, prioritizing speed, leveraging automation, and focusing on meaningful human connections throughout the process.",
        buttonLabel: "More about Tandym's Solutions",
        onClickBtn: function () {
            aboutTandymLink('https://tandymgroup.com/specialized-solutions/');
        },
    },
    {
        title: "Recruitment Expertise",
        image: Recruitment_Expertise_1,
        description: "We specialize in staffing and recruitment, workforce solutions, and managed solutions to meet the needs across all organizations in a multitude of industries. We have a deep understanding of each client's needs, and mentorship of candidates to deliver seamless fits between talent and roles for long-lasting solutions.",
        buttonLabel: "More about Tandym Recruitment",
        onClickBtn: function () {
            aboutTandymLink('https://tandymgroup.com/recruitment-expertise/');
        },
    },
];
