var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import TPCustomModal from '../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import classes from './Home.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import { setLogIn, setLogOut } from '../../store/authSlice';
import { useClientAppDispatch, useClientAppSelector } from '../../store/hook';
import OperationalMetrics from './OperationalMetrics/OperationalMetrics';
import OverView from './Overview/OverView';
import CommonTabs from '../../shared/Tabs/CustomTabs';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import { setEntitlements } from '../../store/entitlementSlice';
import { jwtDecode } from 'jwt-decode';
import LiveContractorDrillDown from '../../components/drill-down/LiveContractorDrillDown';
import OpenPositionsDrillDown from '../../components/drill-down/OpenPositionsDrillDown';
import { solutions } from '../../shared/utilities/enumValues';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import ReactGA from 'react-ga4';
import ContractorsBillDrilldown from '../../components/drill-down/ContractorsBillDrilldown';
import TPToast from '../../../../tandym-web-common/src/shared/components/toast/TPToast';
import DeliveryBenchMark from './DeliveryBenchmark/DeliveryBenchmark';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import { isMobile } from '../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
import RecruitmentTimelineDrillDown from '../../components/drill-down/RecruitmentTimelineDrillDown';
var Home = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t = useState(false), isLoading = _t[0], setIsLoading = _t[1];
    var _u = useState(false), checkbox = _u[0], setCheckbox = _u[1];
    var _v = useState([]), errorMessages = _v[0], setErrorMessages = _v[1];
    var _w = useState(null), firstName = _w[0], setFirstName = _w[1];
    var _x = useState(null), lastName = _x[0], setLastName = _x[1];
    var _y = useState(null), clientName = _y[0], setClientName = _y[1];
    var _z = useState(null), dashboardData = _z[0], setDashboardData = _z[1];
    var _0 = React.useState(null), operationalDrillDownData = _0[0], setOperationalDrillDownData = _0[1];
    var _1 = useState(false), openDrillDown = _1[0], setOpenDrillDown = _1[1];
    var _2 = useState(false), recruitmentDrillDownOpen = _2[0], setRecruitmentDrillDownOpen = _2[1];
    var _3 = React.useState(null), recruitmentDrillDownData = _3[0], setRecruitmentDrillDownData = _3[1];
    var _4 = useState(''), functionalGroup = _4[0], setFunctionalGroup = _4[1];
    var _5 = useState(null), recruitmentTimelineDDwnStartDate = _5[0], setRecruitmentTimelineDDwnStartDate = _5[1];
    var _6 = useState(null), recruitmentTimelineDDwnEndDate = _6[0], setRecruitmentTimelineDDwnEndDate = _6[1];
    var location = useLocation();
    var _7 = useAPI(), httpGet = _7.httpGet, httpPost = _7.httpPost;
    var navigate = useNavigate();
    var dispatch = useClientAppDispatch();
    var email = useClientAppSelector(function (state) { return state.userAuth; }).email;
    var _8 = useClientAppSelector(function (state) { return state.userAuth; }), candidateId = _8.candidateId, googleTrackingId = _8.googleTrackingId, authToken = _8.authToken, isTermsAccepted = _8.isTermsAccepted, isLoggedIn = _8.isLoggedIn, userFirstName = _8.firstName, userLastName = _8.lastName;
    var acceptTermsURL = props.acceptTermsURL;
    var _9 = useState(false), drillDownVisible = _9[0], setDrillDownVisible = _9[1];
    var _10 = useState(false), liveContractorDrillDownVisible = _10[0], setLiveContractorDrillDownVisible = _10[1];
    var _11 = useState(''), drillDownTitle = _11[0], setDrillDownTitle = _11[1];
    var _12 = useState([]), drillDownData = _12[0], setDrillDownData = _12[1];
    var _13 = useState([]), callForActionsData = _13[0], setCallForActionsData = _13[1];
    var _14 = React.useState(), activePositionData = _14[0], setActivePositionData = _14[1];
    var _15 = React.useState(), liveContractorData = _15[0], setLiveContractorData = _15[1];
    var _16 = useState([]), topTenPlacementsList = _16[0], setTopTenPlacementsList = _16[1];
    var _17 = useState([]), availableTandymPositions = _17[0], setAvailableTandymPositions = _17[1];
    var _18 = useState([]), topTenVacanciesList = _18[0], setTopTenVacanciesList = _18[1];
    var avtarNameValidation = function (firstName, lastName) {
        var firstNameValidation = (firstName === null || firstName === void 0 ? void 0 : firstName.trim().charAt(0).toUpperCase()) || '';
        var lastNameValidation = (lastName === null || lastName === void 0 ? void 0 : lastName.trim().charAt(0).toUpperCase()) || '';
        if (firstName && lastName) {
            return "".concat(firstNameValidation).concat(lastNameValidation);
        }
        else if (firstName) {
            return "".concat(firstNameValidation);
        }
        return '';
    };
    sessionStorage.setItem('userInitials', avtarNameValidation(firstName, lastName));
    useEffect(function () {
        if (googleTrackingId) {
            ReactGA.initialize(googleTrackingId, {
                gaOptions: {
                    cookie_flags: 'SameSite=None; Secure',
                    anonymizeIp: true, // Masks user IPs for additional privacy
                },
            });
        }
    }, [googleTrackingId]);
    var totalActivetConractorData = [
        {
            title: 'Total My Open Jobs',
            totalCount: (_a = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.total) !== null && _a !== void 0 ? _a : 0,
        },
        {
            title: 'Consulting Solutions',
            totalCount: (_b = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.consultantTotal) !== null && _b !== void 0 ? _b : 0,
        },
        {
            title: 'Mission Core Solutions',
            totalCount: (_c = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.missionCoreTotal) !== null && _c !== void 0 ? _c : 0,
        },
    ];
    var totalLiveContractorData = [
        {
            title: 'Total My Live Contractors',
            totalCount: (_d = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.total) !== null && _d !== void 0 ? _d : 0,
        },
        {
            title: 'Consulting Solutions',
            totalCount: (_e = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.consultantTotal) !== null && _e !== void 0 ? _e : 0,
        },
        {
            title: 'Mission Core Solutions',
            totalCount: (_f = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.missionCoreTotal) !== null && _f !== void 0 ? _f : 0,
        },
    ];
    var LCConsultingSolutions = (_h = (_g = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.liveContractor) === null || _g === void 0 ? void 0 : _g.liveContractorList) === null || _h === void 0 ? void 0 : _h.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.ConsultingSolutions; });
    var LCMissionCoreSolutions = (_k = (_j = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.liveContractor) === null || _j === void 0 ? void 0 : _j.liveContractorList) === null || _k === void 0 ? void 0 : _k.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.MissionCoreSolutions; });
    var OPConsultingSolutions = (_m = (_l = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.activePostions) === null || _l === void 0 ? void 0 : _l.activePositionList) === null || _m === void 0 ? void 0 : _m.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.ConsultingSolutions; });
    var OPMissionCoreSolutions = (_p = (_o = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.activePostions) === null || _o === void 0 ? void 0 : _o.activePositionList) === null || _p === void 0 ? void 0 : _p.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.MissionCoreSolutions; });
    useEffect(function () {
        var _a, _b, _c;
        if (authToken && typeof authToken === 'string') {
            try {
                var decodedToken = jwtDecode(authToken);
                setFirstName((_a = decodedToken.FirstName) !== null && _a !== void 0 ? _a : null);
                setLastName((_b = decodedToken.LastName) !== null && _b !== void 0 ? _b : null);
                setClientName((_c = decodedToken.ClientName) !== null && _c !== void 0 ? _c : null);
                dispatch(setLogIn({
                    firstName: decodedToken.FirstName,
                    lastName: decodedToken.LastName,
                }));
            }
            catch (error) {
                console.error('Invalid token:', error);
            }
        }
        else {
            console.error('Invalid authToken:', authToken);
        }
    }, [authToken, firstName, lastName, dispatch]);
    useEffect(function () {
        if (isLoggedIn && isTermsAccepted) {
            fetchAllData(candidateId !== null && candidateId !== void 0 ? candidateId : '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isLoggedIn, isTermsAccepted]);
    var fetchAllData = function (candidateId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, Promise.allSettled([
                            fetchDashBoardData(candidateId !== null && candidateId !== void 0 ? candidateId : '00000000-0000-0000-0000-000000000000'),
                            fetchEntitlements(),
                        ]).finally(function () {
                            setIsLoading(false);
                        })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleAcceptTerms = function () { return __awaiter(void 0, void 0, void 0, function () {
        var otp, response_1, _a, Email, FirstName, LastName, ClientName, error_1, errorMsg_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!checkbox) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    setIsLoading(true);
                    otp = sessionStorage.getItem('otp');
                    return [4 /*yield*/, httpPost(acceptTermsURL, null, {
                            email: email,
                            code: otp,
                        })];
                case 2:
                    response_1 = _b.sent();
                    if (!response_1.error) {
                        _a = jwtDecode(response_1.authToken), Email = _a.Email, FirstName = _a.FirstName, LastName = _a.LastName, ClientName = _a.ClientName;
                        setClientName(ClientName !== null && ClientName !== void 0 ? ClientName : '');
                        setFirstName(FirstName !== null && FirstName !== void 0 ? FirstName : null);
                        setLastName(LastName !== null && LastName !== void 0 ? LastName : null);
                        dispatch(setLogIn({
                            candidateId: response_1.mercuryContactId,
                            isLoggedIn: true,
                            email: Email,
                            firstName: FirstName,
                            lastName: LastName,
                            isTermsAccepted: true,
                            googleTrackingId: response_1.googleTrackingId,
                            NajaxaTimeSheetURL: response_1.fEEnvironment.NajaxaTimeSheetURL,
                            authToken: response_1.authToken,
                        }));
                        sessionStorage.setItem('authToken', response_1.authToken);
                        setTimeout(function () {
                            fetchAllData(response_1.mercuryContactId);
                        }, 500);
                    }
                    else {
                        throw new Error(response_1.message || 'Login link is not valid');
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _b.sent();
                    errorMsg_1 = error_1.message || 'Failed to accept terms.';
                    setErrorMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [errorMsg_1], false); });
                    sessionStorage.clear();
                    setTimeout(function () {
                        navigate('/login', { replace: true });
                        dispatch(setLogOut());
                    }, 2000);
                    return [3 /*break*/, 5];
                case 4:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var fetchDashBoardData = function (candidateId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    _h.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CLIENT_DASHBOARD_GET, {
                            clientId: candidateId,
                        }, true)];
                case 1:
                    response = _h.sent();
                    if (!response.error) {
                        setDashboardData(response === null || response === void 0 ? void 0 : response.dashboard);
                        setTopTenPlacementsList((_a = response === null || response === void 0 ? void 0 : response.dashboard) === null || _a === void 0 ? void 0 : _a.top10Placements);
                        setTopTenVacanciesList((_b = response === null || response === void 0 ? void 0 : response.dashboard) === null || _b === void 0 ? void 0 : _b.top10Vacancy);
                        setAvailableTandymPositions((_d = (_c = response === null || response === void 0 ? void 0 : response.dashboard) === null || _c === void 0 ? void 0 : _c.activePostions) === null || _d === void 0 ? void 0 : _d.availablePositions);
                        setActivePositionData((_e = response === null || response === void 0 ? void 0 : response.dashboard) === null || _e === void 0 ? void 0 : _e.activePostions);
                        setLiveContractorData((_f = response === null || response === void 0 ? void 0 : response.dashboard) === null || _f === void 0 ? void 0 : _f.liveContractor);
                        setCallForActionsData((_g = response === null || response === void 0 ? void 0 : response.dashboard) === null || _g === void 0 ? void 0 : _g.callForActions);
                    }
                    else {
                        throw new Error(response.message || 'Failed to fetch candidate info.');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _h.sent();
                    setErrorMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                        (error_2 === null || error_2 === void 0 ? void 0 : error_2.message) || 'Failed to fetch client placements.',
                    ], false); });
                    console.error('Fetch client placements info error:', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchEntitlements = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CLIENT_ENTITLEMENTS_GET)];
                case 1:
                    response = _a.sent();
                    if (!response.error) {
                        dispatch(setEntitlements({
                            entitlementsData: response,
                        }));
                        sessionStorage.setItem('timeSheetEntitlements', response.entitlementDto.timesheets);
                    }
                    else {
                        navigate('/');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _a.sent();
                    setErrorMessages(function (prev) { return __spreadArray(__spreadArray([], prev, true), [
                        (error_3 === null || error_3 === void 0 ? void 0 : error_3.message) || 'Failed to fetch entitlements.',
                    ], false); });
                    console.error('Fetch entitlements error:', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleArrowClick = function (mainTitle, title, data) {
        if (mainTitle === 'My Live Contractors') {
            setLiveContractorDrillDownVisible(true);
        }
        else if (mainTitle === 'My Open Jobs') {
            setDrillDownVisible(true);
        }
        setDrillDownTitle(title);
        setDrillDownData(data);
    };
    var handleBackClick = function () {
        setDrillDownVisible(false);
        setLiveContractorDrillDownVisible(false);
        setOpenDrillDown(false);
        setRecruitmentDrillDownOpen(false);
        setRecruitmentDrillDownData(null);
        setFunctionalGroup('');
    };
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var handleClose = function () {
        sessionStorage.clear();
        dispatch(setLogOut());
        navigate('/login');
    };
    var tabs = [
        {
            label: 'Overview',
            component: isLoading ? (_jsx(TPProgressModal, { isLoading: isLoading })) : (_jsx(OverView, { onArrowClick: function (mainTitle, title, data) {
                    return handleArrowClick(mainTitle, title, data);
                }, firstName: firstName || userFirstName, lastName: lastName || userLastName, clientName: clientName, totalActivetConractorData: totalActivetConractorData, totalLiveContractorData: totalLiveContractorData, activePositionData: activePositionData !== null && activePositionData !== void 0 ? activePositionData : undefined, liveContractorData: liveContractorData !== null && liveContractorData !== void 0 ? liveContractorData : undefined, topTenVacanciesList: topTenVacanciesList, availableTandymPositions: availableTandymPositions, topTenPlacementsList: topTenPlacementsList, callForActionsData: callForActionsData })),
            show: true,
        },
        {
            label: 'Operational Metrics',
            component: openDrillDown ? (_jsx(ContractorsBillDrilldown, { myData: operationalDrillDownData, onBackClick: handleBackClick, title: 'Contractors on Billing' })) : recruitmentDrillDownOpen ? (_jsx(RecruitmentTimelineDrillDown, { graphBarData: recruitmentDrillDownData, functionalGroup: functionalGroup, candidateId: candidateId, onBackClick: handleBackClick, title: 'Recruitment Timeline', operationalMatrixStartDate: recruitmentTimelineDDwnStartDate, operationalMatrixEndDate: recruitmentTimelineDDwnEndDate })) : (_jsx(OperationalMetrics, { setOpenDrillDown: setOpenDrillDown, setOperationaldrillDownData: setOperationalDrillDownData, setRecruitmentDrillDownOpen: setRecruitmentDrillDownOpen, setRecruitmentDrillDownData: setRecruitmentDrillDownData, setFunctionalGroup: setFunctionalGroup, setOperationalMatrixStartDate: setRecruitmentTimelineDDwnStartDate, setOperationalMatrixEndDate: setRecruitmentTimelineDDwnEndDate })),
            show: isMobile()
                ? false
                : (_q = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _q === void 0 ? void 0 : _q.showOperationalMetrics,
        },
        {
            label: 'Delivery Benchmark',
            component: recruitmentDrillDownOpen ? (_jsx(RecruitmentTimelineDrillDown, { graphBarData: recruitmentDrillDownData, functionalGroup: functionalGroup, candidateId: candidateId, onBackClick: handleBackClick, title: 'Recruitment Timeline', operationalMatrixStartDate: recruitmentTimelineDDwnStartDate, operationalMatrixEndDate: recruitmentTimelineDDwnEndDate })) : (_jsx(DeliveryBenchMark, { clientName: clientName, setRecruitmentDrillDownOpen: setRecruitmentDrillDownOpen, setRecruitmentDrillDownData: setRecruitmentDrillDownData, setFunctionalGroup: setFunctionalGroup, setOperationalMatrixStartDate: setRecruitmentTimelineDDwnStartDate, setOperationalMatrixEndDate: setRecruitmentTimelineDDwnEndDate })),
            show: isMobile()
                ? false
                : (_r = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _r === void 0 ? void 0 : _r.showOperationalMetrics,
        },
    ];
    return (_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TPToast, { message: errorMessages.join(' '), open: errorMessages.length > 0, vertical: 'top', horizontal: 'center', type: 'error' }), _jsx(Box, __assign({ sx: { width: '100%', mt: { xs: 1, lg: '34.4px' } }, id: 'home', "data-testid": 'home' }, { children: drillDownVisible ? (_jsx(OpenPositionsDrillDown, { onBackClick: handleBackClick, title: drillDownTitle, drillDownData: drillDownData, consultingSolutions: OPConsultingSolutions, missionCoreSolutions: OPMissionCoreSolutions, setDrillDownTitle: setDrillDownTitle, activePositionData: activePositionData })) : liveContractorDrillDownVisible ? (_jsx(LiveContractorDrillDown, { onBackClick: handleBackClick, title: drillDownTitle, drillDownData: drillDownData, consultingSolutions: LCConsultingSolutions, missionCoreSolutions: LCMissionCoreSolutions, setDrillDownTitle: setDrillDownTitle, totalLiveContractors: (_s = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.liveContractor) === null || _s === void 0 ? void 0 : _s.total, liveContractorData: liveContractorData })) : (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: {
                                px: isMobile() ? 1 : 3,
                                pt: isMobile() ? '2px' : 1,
                            } }, { children: _jsx(CommonTabs, { tabs: tabs, setRecruitmentDrillDownOpen: setRecruitmentDrillDownOpen }) })), _jsxs(Box, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsx(TPCustomModal, { title: 'Terms and Conditions', PaperProps: {
                                        className: classes.paperProps,
                                        sx: { maxWidth: '50rem', height: '70vh' },
                                    }, slotProps: {
                                        backdrop: {
                                            style: { backdropFilter: 'blur(5px)' },
                                        },
                                    }, cancelText: 'Cancel', confirmText: 'I Accept', open: !isTermsAccepted, children: _jsx(TermsAndConditions, {}), DialogTitleProps: { sx: { p: '16px 45px' } }, DialogContentProps: {
                                        className: classes.scrollbar,
                                        sx: { p: '20px 45px' },
                                    }, confirmButtonProps: {
                                        className: classes.confirmButton,
                                    }, DialogActionsProps: {
                                        className: classes.dialogAction,
                                        sx: {
                                            m: '0px 10px 0px 32px',
                                            fontSize: fontSizeDynamic(21),
                                            flexWrap: { xs: 'wrap' },
                                            gap: { xs: '5px' },
                                        },
                                    }, checkboxLabel: 'I confirm that I have read and accept the terms and conditions and privacy policy.', onClose: handleClose, checkboxChecked: checkbox, onCheckboxChange: setCheckbox, onConfirm: handleAcceptTerms })] })] })) }))] })));
};
export default Home;
