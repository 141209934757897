export var tabs = [
    { label: 'Home', path: '/home', show: true },
    { label: 'Placements', path: '/placements', show: true },
    {
        label: 'Time and Expenses',
        path: '/timesheets-expenses',
        show: true,
    },
];
export var popUpcarousel = [
    {
        id: 1,
        jobTitle: 'Neurologist',
        companyName: 'Johns Medical Hospital',
        industry: ' Medical Services',
        location: 'Woodcliff Lake, New Jersey USA',
    },
    {
        id: 2,
        jobTitle: 'Commercial Manager',
        companyName: 'Black Rock',
        industry: 'Finance',
        location: 'Black Rock New Jersey USA',
    },
    {
        id: 1,
        jobTitle: 'Information technology',
        companyName: 'Moback Technologies',
        industry: 'Tecchnical Services',
        location: 'Bangalore, India',
    },
];
