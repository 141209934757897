import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import openPositionsIIconCarouselBackgroundImg from '../../../images/openPositionsIIconCarouselBackgroundImg.png';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
export var overViewStyles = {
    text: {
        color: "".concat(colorCodes.textColors.lightGray),
        fontSize: fontSizeDynamic(14),
        fontWeight: 500,
    },
    number: {
        color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
        fontSize: fontSizeDynamic(48),
        fontWeight: 500,
    },
    consultingSolution: {
        color: "".concat(colorCodes.textColors.charcoal),
        fontSize: fontSizeDynamic(16),
        fontWeight: 450,
    },
    colorCircle: {
        color: "".concat(colorCodes.circle.blueGray),
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    tileTitle: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(16),
        fontWeight: 500,
    },
    tileNumber: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(32),
        fontWeight: 300,
    },
    latestTandymVacancyTitle: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(16),
        fontWeight: 500,
    },
    latestTandymVacancyText: {
        color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
        fontSize: fontSizeDynamic(16),
        fontWeight: 600,
        cursor: 'pointer',
    },
    placementsText: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(24),
        fontWeight: 600,
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    image: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        flex: 1,
    },
    liveContractorTitleBox: {
        mt: 2.5,
        p: 0,
    },
    colorIdentifier: { borderRadius: '100px', width: '12px', height: '12px' },
    tileContainer: { mt: 3, gap: 3, display: 'flex', flexWrap: 'wrap' },
    consultingSolutionsBox: {
        px: 1,
        mt: 4,
        justifyContent: 'space-between',
    },
    imageBorderBottom: {
        borderBottom: "2px solid ".concat(colorCodes.borderColor.QuillGray),
    },
    smallGridStyle: {
        mt: 2,
        ml: 2,
        backgroundColor: colorCodes.backgroundColors.white,
    },
    hoverEffect: {
        '&:hover': {
            cursor: 'pointer',
            '& svg': {
                transform: 'scale(1.1)',
            },
        },
    },
};
export var rightSideBoxStyles = {
    carouseBoxCardImageContainer: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'start',
    },
    carouselImageTitle: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(16),
        fontWeight: 550,
    },
    carouselImageSubtext: {
        color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
        fontSize: fontSizeDynamic(14),
        fontStyle: 'italic',
        fontWeight: 400,
        ml: 3,
    },
    imageBorderBottom: {
        mt: '-1.9%',
        border: "1px solid ".concat(colorCodes.borderColor.QuillGray),
    },
    avatarNameText: {
        color: "".concat(colorCodes.backgroundColors.white),
        fontSize: fontSizeDynamic(24),
        fontWeight: 500,
    },
    carouselAvatarDetailsText: {
        color: 'rgba(28, 27, 31, 0.6)',
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    carouselAvatarDetailsValue: {
        color: "".concat(colorCodes.iconBackgroundColor.mirage),
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    carouselAvatarDetailsMissionCore: {
        color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    cardTitle: {
        color: "".concat(colorCodes.textColors.woodSmock),
        fontSize: fontSizeDynamic(20),
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
    },
    cardDescription: {
        color: "".concat(colorCodes.textColors.Iridium),
        fontSize: fontSizeDynamic(16),
        fontWeight: 300,
    },
    description: {
        color: "".concat(colorCodes.textColors.Iridium),
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
};
export var InfoIconCarousel = {
    title: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(24),
        fontWeight: 500,
    },
    subTitle: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
    },
    description: {
        color: "".concat(colorCodes.textColors.black),
        fontSize: fontSizeDynamic(20),
        fontWeight: 500,
    },
    descriptionText: {
        color: "".concat(colorCodes.textColors.Iridium),
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
    },
    subTitleBlue: {
        color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
        fontSize: fontSizeDynamic(20),
        fontWeight: 500,
    },
    avatarBox: {
        width: '7%',
        height: '7%',
        background: "".concat(colorCodes.backgroundColors.sandStone),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    companyNameTextKey: {
        color: "".concat(colorCodes.backgroundColors.sandStone),
        fontSize: fontSizeDynamic(16),
        fontweight: 400,
    },
    companyNameTextValue: {
        color: "".concat(colorCodes.textColors.midNightMoss),
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
    },
    backgroundImage: {
        padding: '16px 16px 25px 24px',
        backgroundImage: "url(".concat(openPositionsIIconCarouselBackgroundImg, ")"),
    },
};
export var liveContractorDrillDownStyles = {
    functionalGroupName: {
        color: "".concat(colorCodes.textColors.lightGray),
        fontWeight: 400,
    },
    functionalGroupCount: {
        color: "".concat(colorCodes.textColors.black),
        fontWeight: 600,
    },
    totalValue: {
        color: "".concat(colorCodes.textColors.black),
        fontWeight: 400,
    },
    functionalGroupCircle: {
        border: '1px solid #49454F',
        borderRadius: '20px',
        textAlign: 'center',
        fontWeight: 500,
        letterSpacing: '0.1px',
        padding: '3px',
    },
    filterBoxes: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '6px',
    },
    consultingSolutionsHeader: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '1rem',
        position: 'relative',
        marginTop: '16px',
    },
    divider: {
        border: "1px solid ".concat(colorCodes.borderColor.QuillGray),
        transform: 'rotate(34deg)',
    },
};
export var welcomeSectionStyles = {
    welcomeSectionContainer: {
        paddingLeft: '24px',
        paddingTop: '40px',
        paddingBottom: '90px',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        flex: 1,
    },
};
