var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { TableRow, TableCell, Drawer, Box } from '@mui/material';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { isMobile } from '../../../../tandym-web-common/src/shared/utilities/isMobileEntitlementFlag';
export var borderColor = '#eaecf0';
export var StyledTableCell = styled(TableCell)({
    fontWeight: 'lighter',
    whiteSpace: 'nowrap',
    borderTop: "1px solid ".concat(borderColor),
});
export var StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: '#f9fafb',
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#ffffff',
    },
    '& .sticky': {
        backgroundColor: '#ffffff',
    },
});
var StickyHeaderCell = styled(TableCell)({
    fontWeight: 'lighter',
    whiteSpace: 'nowrap',
    position: isMobile() ? 'relative' : 'sticky',
    backgroundColor: 'white',
    borderBottom: "1px solid ".concat(borderColor),
});
export var CandidateCell = styled(StickyHeaderCell)({
    left: 0,
    zIndex: 3,
    borderRight: "1px solid ".concat(borderColor),
});
export var EmailCell = styled(StickyHeaderCell)({
    right: 0,
    zIndex: 4,
    borderLeft: "1px solid ".concat(borderColor),
});
export var CustomScrollContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-x: auto;\n  white-space: nowrap;\n  background-color: #f0f0f0;\n\n  ::-webkit-scrollbar-track {\n    background-color: transparent; /* Track color */\n  }\n\n  /* Firefox */\n  scrollbar-width: thin;\n  scrollbar-color: #2a70ea transparent;\n"], ["\n  overflow-x: auto;\n  white-space: nowrap;\n  background-color: #f0f0f0;\n\n  ::-webkit-scrollbar-track {\n    background-color: transparent; /* Track color */\n  }\n\n  /* Firefox */\n  scrollbar-width: thin;\n  scrollbar-color: #2a70ea transparent;\n"])));
export var placementsStyles = {
    tabBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stickyColumCandidate: {
        position: isMobile() ? 'relative' : 'sticky',
        left: 0,
        zIndex: 1,
        backgroundColor: 'white',
        minWidth: isMobile() ? 0 : 175,
        borderRight: "1px solid ".concat(borderColor),
    },
    stickyColumEmail: {
        position: isMobile() ? 'relative' : 'sticky',
        right: 0,
        zIndex: 2,
        backgroundColor: 'white',
        textAlign: 'center',
        minWidth: isMobile() ? 0 : 50,
        borderLeft: "1px solid ".concat(borderColor),
    },
    drawerClass: {
        padding: 4,
        bgcolor: 'background.paper',
        boxShadow: 1,
        overflow: 'hidden',
    },
    tableCellCustom: {
        minWidth: isMobile() ? 0 : 175,
        fontSize: fontSizeDynamic(14),
        fontWeight: 400,
    },
    avatar: {
        width: 24,
        height: 24,
        mr: 1,
        fontSize: fontSizeDynamic(18),
        lineHeight: 0,
    },
    tableHead: {
        fontSize: fontSizeDynamic(24),
        lineHeight: fontSizeDynamic(20),
        fontWeight: 400,
    },
    tableNote: {
        fontSize: fontSizeDynamic(14),
        lineHeight: fontSizeDynamic(18.8),
        fontWeight: 200,
        color: '#686868',
    },
    candidateName: {
        fontSize: fontSizeDynamic(14),
        fontWeight: 500,
    },
};
// Drawer
export var TPStyledDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
        padding: 20,
        overflow: 'scroll',
        top: '10%',
    },
});
export var TPStyledBox = styled(Box)({
    padding: '5px',
    backgroundColor: 'white',
    '& .MuiTypography-root.MuiTypography-h6': {
        color: '#212121',
        fontSize: fontSizeDynamic(24),
        fontWeight: 200,
    },
    '& .MuiFormLabel-root, .MuiFormControlLabel-label': {
        color: '#383838',
        fontSize: fontSizeDynamic(16),
        fontWeight: 400,
    },
    '&.MuiTypography-root, .MuiInputBase-root': {
        color: '#686868',
        fontSize: fontSizeDynamic(16),
        fontWeight: 200,
    },
    '& .date-picker': {
        color: '#2A70EA',
        fontSize: fontSizeDynamic(16),
    },
});
var templateObject_1;
