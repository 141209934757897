import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import NoDataFound from '../NoDataFound/NoDataFound';
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
var RecruitmentTimeline = function (props) {
    var recruitmentTimelineData = props.recruitmentTimelineData, setRecruitmentDrillDownOpen = props.setRecruitmentDrillDownOpen, setRecruitmentDrillDownData = props.setRecruitmentDrillDownData, isLoading = props.isLoading, selectedFunctionalGroup = props.selectedFunctionalGroup, setFunctionalGroup = props.setFunctionalGroup;
    var data = recruitmentTimelineData ? recruitmentTimelineData : [];
    var handleClick = function (e) {
        var getDrillDownData = data[e.point.index];
        setRecruitmentDrillDownOpen && setRecruitmentDrillDownOpen(true);
        setRecruitmentDrillDownData &&
            setRecruitmentDrillDownData(getDrillDownData);
        setFunctionalGroup && setFunctionalGroup(selectedFunctionalGroup !== null && selectedFunctionalGroup !== void 0 ? selectedFunctionalGroup : '');
    };
    var graphBarColor = function (label) {
        if (label === 'Total') {
            return 'black';
        }
        else if (label === 'Search' || label === 'Submit' || label === 'Accept') {
            return '#2b6fea';
        }
        else if (label === 'Interview' ||
            label === 'Offer' ||
            label === 'Start') {
            return '#a6a6a6';
        }
        else {
            return '#2b6fea';
        }
    };
    var options = {
        accessibility: {
            enabled: false,
        },
        chart: {
            type: 'waterfall',
            scrollablePlotArea: {
                minWidth: 600,
            },
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        xAxis: {
            categories: recruitmentTimelineData === null || recruitmentTimelineData === void 0 ? void 0 : recruitmentTimelineData.map(function (item) { return item.label; }),
            title: {
                text: null,
            },
        },
        yAxis: {
            title: {
                text: 'Average Number of Days',
            },
        },
        tooltip: {
            useHTML: true,
            backgroundColor: '#f7f7f7',
            borderColor: '#aaa',
            borderRadius: 5,
            style: {
                color: '#333',
            },
            shadow: true,
            formatter: function () {
                var point = this.point;
                var dotColor = point.color;
                if (point.name.toLowerCase() === 'total') {
                    return "\n            <span style=\"font-size: 10px\">".concat(point.name, "</span><br> \n            <span style=\"color: ").concat(dotColor, ";\">\u25CF</span> \n            Avg. number of days from vacancy to start: <b>").concat(point.isNaNValue ? 'N/A' : point.y, "</b>\n          ");
                }
                else {
                    return "\n            <span style=\"font-size: 10px\">".concat(point.name, "</span><br> \n            <span style=\"color: ").concat(dotColor, ";\">\u25CF</span> \n            Avg. number of days from previous step: <b>").concat(point.isNaNValue ? 'N/A' : point.y, "</b>\n          ");
                }
            },
        },
        plotOptions: {
            waterfall: {
                borderRadius: 0,
                borderWidth: 0,
            },
            series: {
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function () {
                        var value = this.y;
                        var label = this.point.name;
                        // Default label color
                        var labelColor = '#000000';
                        if (value !== 0) {
                            if (label === 'Total' ||
                                label === 'TOTAL' ||
                                label === 'Search' ||
                                label === 'Submit' ||
                                label === 'Accept') {
                                labelColor = '#ffffff';
                            }
                        }
                        if (this.point.isNaNValue) {
                            return "<span style=\"color:rgb(0, 0, 0); font-weight: bold;\">N/A</span>";
                        }
                        return "<span style=\"color: ".concat(labelColor, "; font-weight: bold;\">").concat(value, "</span>");
                    },
                },
                point: {
                    events: {
                        click: handleClick,
                    },
                },
            },
        },
        series: [
            {
                type: 'waterfall',
                data: recruitmentTimelineData === null || recruitmentTimelineData === void 0 ? void 0 : recruitmentTimelineData.map(function (item) { return ({
                    name: item.label,
                    y: item.value === 'N/A' ? Number('0') : Number(item.value),
                    isNaNValue: item.value === 'N/A',
                    isSum: item.label === 'Total' && item.value !== 'N/A',
                    color: graphBarColor(item.label),
                }); }),
                dataLabels: {
                    enabled: true,
                    useHTML: true,
                },
            },
        ],
    };
    return (_jsx(_Fragment, { children: recruitmentTimelineData && recruitmentTimelineData.length > 0 ? (_jsx(HighchartsReact, { highcharts: Highcharts, options: options })) : (_jsx(NoDataFound, { isLoading: isLoading })) }));
};
export default RecruitmentTimeline;
